import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Chakra from '@chakra-ui/vue';

import theme from './assets/theme/theme';
import {
  faStickyNote,
  faBullseye,
  faArrowLeft,
  faArrowRight,
  faFileAlt,
  faAngleDoubleRight,
  faPuzzlePiece,
  faChalkboard,
  faSearch,
  faBookmark,
  faImages,
  faHashtag,
  faLink,
  faAt,
  faCalendar,
  faEllipsisH,
  faEllipsisV,
  faSlidersH,
  faVideo,
  faFilm,
  faBars,
  faSmile,
  faUser,
  faUsers,
  faSuitcase,
  faMapPin,
  faPhone,
  faEnvelope,
  faPen,
  faWindowClose,
  faFilePdf,
  faFileExcel,
  faFileWord,
  faFileCsv,
  faUserFriends,
  faArrowCircleDown,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faClock,
  faTrash,
  faEye
} from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

import vSelect from 'vue-select';
import VueTimepicker from 'vue2-timepicker';
import VueSweetalert2 from "vue-sweetalert2";

import 'sweetalert2/dist/sweetalert2.min.css';

// CSS
import 'vue2-timepicker/dist/VueTimepicker.css';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'vue-tour/dist/vue-tour.css';
import 'vue-swatches/dist/vue-swatches.css';
import 'vue-select/dist/vue-select.css';

import VueTour from 'vue-tour';
import VueQuillEditor from 'vue-quill-editor';

import Vuelidate from 'vuelidate';
import { createProvider } from './vue-apollo';
import OneSignalVue from 'onesignal-vue';
import PanZoom from 'vue-panzoom';
import VueGtag from 'vue-gtag';

import Eagle from 'eagle.js';
// import animate.css for slide transition
import 'animate.css';

Vue.use(Eagle);
import imagePreview from 'image-preview-vue';
import 'image-preview-vue/lib/imagepreviewvue.css';
import VueSocialSharing from 'vue-social-sharing';

Vue.use(VueSocialSharing);

Vue.use(VueSweetalert2);

Vue.use(imagePreview);

Vue.use(VueTour);

Vue.use(VueQuillEditor /* { default global options } */);

Vue.component('v-select', vSelect);

Vue.component('v-timepicker', VueTimepicker);

Vue.use(require('vue-moment'));

Vue.use(Chakra, {
  extendTheme: theme,
  icons: {
    iconPack: 'fa',
    iconSet: {
      faStickyNote,
      faBullseye,
      faArrowLeft,
      faArrowRight,
      faFileAlt,
      faAngleDoubleRight,
      faPuzzlePiece,
      faChalkboard,
      faSearch,
      faBookmark,
      faImages,
      faHashtag,
      faLink,
      faAt,
      faCalendar,
      faEllipsisH,
      faEllipsisV,
      faSlidersH,
      faVideo,
      faFilm,
      faBars,
      faSmile,
      faUser,
      faUsers,
      faSuitcase,
      faMapPin,
      faPhone,
      faEnvelope,
      faPen,
      faWindowClose,
      faFilePdf,
      faFileExcel,
      faFileWord,
      faFileCsv,
      faUserFriends,
      faArrowCircleDown,
      faChevronUp,
      faChevronDown,
      faChevronLeft,
      faChevronRight,
      faClock,
      faTrash,
      faEye
    },
    extend: {
      ...theme.icons,
    },
  },
});

Vue.use(Vuelidate);

Vue.use(OneSignalVue);

Vue.use(PanZoom);

Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GA_ID, cookieDomain: 'none' },
  },
  router
);
Vue.config.productionTip = false;

window.Vue = Vue;

window.vm = new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  render: (h) => h(App),
  beforeMount() {
    this.$OneSignal.init({
      allowLocalhostAsSecureOrigin: process.env.NODE_ENV !== 'production',
      appId:
        process.env.VUE_APP_ONESIGNAL_APP_ID ||
        'a9e3ccd6-6db4-46c0-80d8-7c91d10c741a',
      notifyButton: {
        enable: false,
      },
      notificationClickHandlerMatch: 'origin',
      notificationClickHandlerAction: 'focus',
    });
  },
  // Staging Values
  // appId: '8ea89031-31d7-49bd-a167-c919bcdfc915',
  // safari_web_id: 'web.onesignal.auto.5f80e2fb-b063-4ecb-90f7-0c7e45de9678',
}).$mount('#app');
