import Vue from 'vue';
import VueRouter from 'vue-router';
// import Index from '../views/Index.vue';
import Auth from '../views/Auth/Index.vue';
import App from '../views/App/Index.vue';

import NProgress from 'nprogress';

Vue.use(VueRouter);

const authenticatedGuard = (to, from, next) => {
  const isAuthenticated = localStorage.getItem('apollo-token');
  if (to.name === 'Share' || to.name === 'ViewPresentation') {
    next();
    return;
  }
  if (!isAuthenticated) {
    next({ name: 'Login' });
  } else {
    next();
  }
};

const notAuthenticatedGuard = (to, from, next) => {
  const isAuthenticated = localStorage.getItem('apollo-token');
  if (!isAuthenticated) {
    next();
  } else {
    next({ name: 'GrowthHome' });
  }
};

const routes = [
  {
    path: '/',
    redirect: '/auth',
    // name: 'Index',
    // component: Index,
  },
  {
    path: '/auth',
    component: Auth,
    children: [
      { path: '', redirect: 'login' },
      {
        path: 'login',
        name: 'Login',
        component: () =>
          import(/* webpackChunkName: "auth" */ '../views/Auth/Login.vue'),
        beforeEnter: notAuthenticatedGuard,
      },
      {
        path: 'signup',
        component: () =>
          import(/* webpackChunkName: "auth" */ '../views/Auth/SignUp.vue'),
        beforeEnter: notAuthenticatedGuard,
      },
      {
        path: 'forgot-password',
        component: () =>
          import(
            /* webpackChunkName: "auth" */ '../views/Auth/ForgotPassword.vue'
          ),
        beforeEnter: notAuthenticatedGuard,
      },
      {
        path: 'set-password',
        component: () =>
          import(
            /* webpackChunkName: "auth" */ '../views/Auth/SetNewPassword.vue'
          ),
        beforeEnter: notAuthenticatedGuard,
      },
      {
        path: 'verify-email',
        component: () =>
          import(
            /* webpackChunkName: "auth" */ '../views/Auth/VerifyEmail.vue'
          ),
      },
      {
        path: 'new-invite',
        component: () =>
          import(/* webpackChunkName: "auth" */ '../views/Auth/NewInvite.vue'),
      },
    ],
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: () => import('../views/Onboarding/Index.vue'),
    beforeEnter: authenticatedGuard,
  },
  {
    path: '/onboarding/no-team',
    name: 'NoTeam',
    component: () => import('../views/Onboarding/NoTeam.vue'),
    beforeEnter: authenticatedGuard,
  },
  {
    path: '/app',
    component: App,
    children: [
      {
        path: '',
        redirect: 'growth-framework',
      },
      {
        path: 'growth-framework',
        component: () =>
          import(
            /* webpackChunkName: "growth-framework" */ '../views/App/GrowthFramework/Index.vue'
          ),
        children: [
          {
            path: 'foundation',
            name: 'Foundation',
            component: () =>
              import(
                /* webpackChunkName: "growth-framework" */ '../views/App/GrowthFramework/Foundation.vue'
              ),
          },
          {
            path: 'business-model',
            name: 'BusinessModel',
            component: () =>
              import(
                /* webpackChunkName: "growth-framework" */ '../views/App/GrowthFramework/BusinessModel.vue'
              ),
          },
          {
            path: 'competition',
            name: 'Competition',
            component: () =>
              import(
                /* webpackChunkName: "growth-framework" */ '../views/App/GrowthFramework/Competition.vue'
              ),
          },
          {
            path: 'market-potential',
            name: 'MarketPotential',
            component: () =>
              import(
                /* webpackChunkName: "growth-framework" */ '../views/App/GrowthFramework/MarketPotential.vue'
              ),
          },
          {
            path: 'risk-assessment',
            name: 'RiskAssessment',
            component: () =>
              import(
                /* webpackChunkName: "growth-framework" */ '../views/App/GrowthFramework/RiskAssessment.vue'
              ),
          },
          {
            path: 'overview',
            name: 'Overview',
            component: () =>
              import(
                /* webpackChunkName: "growth-framework" */ '../views/App/GrowthFramework/Generate/Index.vue'
              ),
          },
          {
            path: '',
            name: 'GrowthHome',
            component: () =>
              import(
                /* webpackChunkName: "growth-framework" */ '../views/App/GrowthFramework/Home.vue'
              ),
          },
          {
            path: 'subscription',
            component: () =>
              import(
                /* webpackChunkName: "growth-framework" */ '../views/App/Subscription/Index.vue'
              ),
            children: [
              {
                path: '',
                name: 'Subscription',
                component: () =>
                  import(
                    /* webpackChunkName: "Share" */ '../views/App/Subscription/Plans.vue'
                  ),
              },
              {
                path: 'manage',
                name: 'ManageSubscription',
                component: () =>
                  import(
                    /* webpackChunkName: "Share" */ '../views/App/Subscription/Manage.vue'
                  ),
              },
              {
                path: 'credits',
                name: 'Credits',
                component: () =>
                  import(
                    /* webpackChunkName: "Share" */ '../views/App/Subscription/AiCredits.vue'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'slack',
        name: 'Slack',
        component: () =>
          import(
            /* webpackChunkName: "slack" */ '../views/App/slack/Index.vue'
          ),
      },
      {
        path: 'events',
        name: 'Events',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/App/Events/Events.vue'
          ),
      },
      {
        path: 'events/:id',
        name: 'EventDetails',
        component: () => import('../views/App/Events/SingleEvent.vue'),
      },
      {
        path: 'overview',
        name: 'Overview',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/App/GrowthFramework/Overview.vue'
          ),
      },
      {
        path: 'timeline',
        name: 'Timeline',
        component: () =>
          import(
            /* webpackChunkName: "timeline" */ '../views/App/Timeline/Index.vue'
          ),
      },
      {
        path: 'search-landing',
        name: 'search-landing',
        component: () => import('../views/App/Explore/SearchLanding.vue'),
        props: true,
      },
      {
        path: 'all-search-results',
        name: 'all-search-results',
        component: () => import('../views/App/Explore/AllSearchResults.vue'),
        props: true,
      },
      {
        path: 'explore',
        component: () => import('../views/App/Explore/Layout.vue'),
        children: [
          {
            path: '',
            name: 'Explore',
            component: () => import('../views/App/Explore/Index.vue'),
          },
          {
            path: 'saved',
            name: 'saved-items',
            component: () => import('../views/App/Explore/Saved.vue'),
          },
          {
            path: 'notification',
            name: 'notifications',
            component: () =>
              import('../views/App/Explore/NotificationsPage.vue'),
          },
          {
            path: 'post/:id',
            name: 'ViewInsight',
            component: () =>
                import(
                    /* webpackChunkName: "Insight" */ '../views/App/Insight/ViewPost.vue'
                    ),
          },
        ],
      },
      {
        path: 'insight',
        component: () =>
          import(
            /* webpackChunkName: "Insight" */ '../views/App/Insight/Layout.vue'
          ),
        children: [
          {
            path: '',
            name: 'Insight',
            component: () =>
              import(
                /* webpackChunkName: "Insight" */ '../views/App/Insight/Index.vue'
              ),
          },
        ],
      },
      // {
      //   path: 'profile/user/:userId',
      //   name: 'profile',
      //   props: true,
      //   component: () => import('@/views/App/Profile/components/Profile.vue'),
      // },
      // {
      //   path: 'profile/company/:company',
      //   name: 'company-profile',
      //   props: true,
      //   component: () => import('@/views/App/Profile/components/Profile.vue'),
      // },
      {
        path: 'profile/user/:userId',
        name: 'Profile',
        props: true,
        component: () => import('@/views/App/Profile/PersonalProfile.vue'),
      },
      {
        path: 'profile/company/:companyId',
        name: 'CompanyProfile',
        props: true,
        component: () => import('@/views/App/Profile/CompanyProfile.vue'),
      },
      {
        path: 'messaging',
        component: () =>
          import(
            /* webpackChunkName: "timeline" */ '../views/App/Messaging/Index.vue'
          ),
        children: [
          {
            path: '',
            name: 'messaging',
            component: () =>
              import('../views/App/Messaging/StartConversation.vue'),
          },
          {
            path: 'messaging/channel',
            name: 'ChannelMessaging',
            component: () =>
              import('../views/App/Messaging/channelChatWindow.vue'),
          },
          {
            path: 'messaging/user',
            name: 'UserMessaging',
            component: () =>
              import('../views/App/Messaging/userChatWindow.vue'),
          },
          {
            path: 'channel/create',
            name: 'CreateNewChannel',
            component: () =>
              import('../views/App/Messaging/CreateNewChannel.vue'),
          },
        ],
      },
      {
        path: 'presentation',
        component: () =>
          import(
            /* webpackChunkName: "Presentation" */ '../views/App/Presentation/Index.vue'
          ),
        children: [
          {
            path: '',
            name: 'presentation',
            component: () =>
              import(
                /* webpackChunkName: "Presentation" */ '../views/App/Presentation/Home.vue'
              ),
            meta: {
              hideNotes: true,
            },
          },
          {
            path: 'edit/:id',
            name: 'EditPresentation',
            component: () =>
              import(
                /* webpackChunkName: "Presentation" */ '../views/App/Presentation/EditPresentation.vue'
              ),
            props: { isEdit: true },
            meta: {
              hideNotes: true,
            },
          },
          {
            path: 'view/:id',
            name: 'ViewPresentation',
            component: () =>
              import(
                /* webpackChunkName: "Presentation" */ '../views/App/Presentation/EditPresentation.vue'
              ),
            props: { isEdit: false },
            meta: {
              hideNotes: true,
            },
          },
        ],
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: () =>
          import(
            /* webpackChunkName: "Calendar" */ '../views/App/Calendar/Index.vue'
          ),
      },
      {
        path: 'share',
        name: 'Share',
        component: () =>
          import(
            /* webpackChunkName: "Share" */ '../views/App/Share/Index.vue'
          ),
      },
    ],
    beforeEnter: authenticatedGuard,
    // meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

// router.beforeEach((to, from, next) => {
//   const isAuthenticated = localStorage.getItem('apollo-token');
//   const isSharePage = to.matched.some((route) => route.name === 'Share');
//   if (isSharePage) {
//     next();
//     return;
//   }
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (!isAuthenticated) {
//       next({ name: 'Login' });
//     } else {
//       next();
//     }
//   } else {
//     if (!isAuthenticated) {
//       next();
//     } else {
//       next({ name: 'GrowthHome' });
//     }
//   }
// });

export default router;
