<template>
  <div class="app">
    <!-- <c-button @click.native="openSideMenu" class="menu xl:none" variant="icon">
      <c-icon size="4" color="vc-orange.600" name="bars" />
    </c-button> -->
    <div
      class="app__sidebar"
      :class="{
        'app__sidebar--open': isMenuOpen,
        'app__sidebar--ontour': isTourActive,
      }"
      v-if="!isShareView"
    >
      <div class="app__sidebar__header">
        <c-box
          class="app__sidebar__header__trigger"
          @click="isCompanyDrawerOpen = !isCompanyDrawerOpen"
        >
          <c-avatar
            backgroundColor="#fff"
            color="#000"
            size="md"
            :src="activeCompany ? activeCompany.logo : null"
            :name="activeCompany ? activeCompany.name : ''"
            borderWidth="0px"
          />
          <svg>
            <use href="@/assets/icons/chevron-down.svg#chevron" />
          </svg>
        </c-box>
      </div>
      <ul class="app__sidebar__links">
        <div v-if="currentSwitchApp === 'community'">
          <li class="app__sidebar__link">
            <router-link active-class="active" to="/app/explore">
              <svg>
                <use href="@/assets/icons/newspaper-line.svg#newspaper" />
              </svg>
              <span>Explore</span>
            </router-link>
          </li>
          <li class="app__sidebar__link">
            <router-link exact-active-class="active" to="/app/events">
              <svg>
                <use href="@/assets/icons/dashboard-line.svg#dashboard" />
              </svg>
              <span>Events</span>
            </router-link>
          </li>

          <li class="app__sidebar__link">
            <router-link active-class="active" to="/app/messaging">
              <svg>
                <use href="@/assets/icons/comment-fill.svg#comment" />
              </svg>
              <span class="custom_badge" v-if="unreadMessageCount > 0">{{
                unreadMessageCount
              }}</span>
              <span>Messaging</span>
            </router-link>
          </li>
        </div>

        <div v-if="currentSwitchApp === 'business'">
          <li class="app__sidebar__link">
            <router-link exact-active-class="active" to="/app/overview">
              <svg>
                <use href="@/assets/icons/dashboard-line.svg#dashboard" />
              </svg>
              <span>Overview</span>
            </router-link>
          </li>
          <li class="app__sidebar__link">
            <router-link active-class="active" to="/app/growth-framework">
              <svg>
                <use href="@/assets/icons/growth-line.svg#growth" />
              </svg>
              <span>Growth Framework</span>
            </router-link>
          </li>
          <li class="app__sidebar__link">
            <router-link exact-active-class="active" to="/app/timeline">
              <svg>
                <use href="@/assets/icons/timeline-line.svg#timeline" />
              </svg>
              <span>Timeline</span>
            </router-link>
          </li>
        </div>
        <br />
        <li>
          <div class="app_switcher">
            <span
              @click="switchApp('business')"
              :class="currentSwitchApp === 'business' ? 'activeApp' : ''"
              ><i class="fas fa-briefcase"></i
            ></span>
            <span
              @click="switchApp('community')"
              :class="currentSwitchApp === 'community' ? 'activeApp' : ''"
              ><i class="fas fa-user-friends"></i
            ></span>
          </div>
        </li>

        <li
          style="text-align: center; margin-top: 20px"
          class="sidebar_notes"
          @click="$refs.notesCard.openNotes()"
        >
          <i class="far fa-edit" @click="$refs.notesCard.openNotes()"></i>
        </li>
        <li class="app__sidebar__link">
          <!-- :disabled="!getCurrentPlan.access.presentation"
            :event="getCurrentPlan.access.presentation ? 'click' : ''"

                      :class="{ disabled: !getCurrentPlan.access.presentation }" -->
          <!-- <router-link
            active-class="active"
            to="/app/presentation"
            v-if="isStaging"
          >
            <svg>
              <use href="@/assets/icons/presentation.svg#presentation" />
            </svg>
            <span>Presentation</span>
          </router-link> -->
          <!-- <svg
            v-chakra="{
              w: '15px',
              h: '15px',
              fill: '#ef5323',
              position: 'absolute',
              top: '10px',
              right: '10px',
            }"
          >
            <use href="@/assets/icons/icon-lock.svg#icon-lock"></use>
          </svg> -->
        </li>
        <!-- <li v-if="isStaging" class="app__sidebar__link">
          <router-link exact-active-class="active" to="/app/calendar">
            <svg>
              <use
                href="@/assets/icons/calendar-event-line.svg#calendar-event"
              ></use>
            </svg>
            <span>Calendar</span>
          </router-link>
        </li> -->
      </ul>
      <div class="app__sidebar__footer">
        <div v-if="isCompanyOwner || isCompanyAdmin">
          <c-button
            @click="$router.push({ name: 'Credits' })"
            size="xs"
            variant-color="vc-orange"
          >
            Buy Points
          </c-button>
        </div>

        <c-box
          v-if="isCompanyOwner || isCompanyAdmin"
          rounded="md"
          d="flex"
          mt="3"
          flex-direction="column"
          align-items="center"
          bg="white"
          px="4"
          py="3"
          mb="3"
          w="80%"
        >
          <c-text
            color="vc-orange"
            fontSize=".7em"
            mb="2"
            textAlign="center"
            v-if="isPaidPlan"
          >
            You're on
          </c-text>
          <c-text
            color="vc-orange"
            fontSize=".7em"
            mb="2"
            textAlign="center"
            v-else
          >
            {{ isFreePlan ? `You're on Free Plan` : 'Upgrade your Plan now' }}
          </c-text>
          <c-button
            @click="$router.push({ name: 'Subscription' })"
            size="xs"
            variant-color="vc-orange"
          >
            {{ isPaidPlan ? getCurrentPlan.name : 'Upgrade' }}
          </c-button>
        </c-box>
        <c-avatar
          size="md"
          :name="`${user.firstname} ${user.lastname}`"
          :src="user.profilePhoto"
          borderWidth="0px"
          cursor="pointer"
        />
        <div class="app__sidebar__footer__links">
          <c-popover placement="right-start">
            <c-popover-trigger>
              <c-link mr="4">
                <svg>
                  <use href="@/assets/icons/settings-fill.svg#settings" />
                </svg>
              </c-link>
            </c-popover-trigger>
            <c-popover-content w="250px" zIndex="modal" class="pop_content">
              <c-popover-body p="0">
                <c-list>
                  <template>
                    <c-list-item
                      fontSize="12px"
                      fontWeight="bold"
                      py="2"
                      px="3"
                    >
                      Integrations
                    </c-list-item>
                    <c-list-item display="flex" borderBottomWidth="1px">
                      <c-pseudo-box
                        as="a"
                        @click="$router.push({ name: 'Slack' })"
                        py="2"
                        px="3"
                        w="100%"
                        :_hover="{
                          textDecoration: 'none',
                          cursor: 'pointer',
                        }"
                      >
                        <img
                          v-if="!user.slackData"
                          alt="Add to Slack"
                          height="40"
                          width="139"
                          src="https://platform.slack-edge.com/img/add_to_slack.png"
                          srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                        />
                        <c-text v-else>Integrated to Slack</c-text>
                      </c-pseudo-box>
                      <!-- <a href="https://slack.com/oauth/v2/authorize?client_id=514553026068.2928715089363&scope=app_mentions:read,commands,users.profile:read,chat:write,incoming-webhook&user_scope="></a> -->
                    </c-list-item>
                  </template>
                  <c-list-item
                    v-if="isCompanyOwner"
                    display="flex"
                    borderBottomWidth="1px"
                  >
                    <c-pseudo-box
                      as="a"
                      py="2"
                      px="3"
                      w="100%"
                      :_hover="{
                        textDecoration: 'none',
                        cursor: 'pointer',
                      }"
                      @click="$router.push('/app/overview')"
                      >Company Settings</c-pseudo-box
                    >
                  </c-list-item>
                  <c-list-item display="flex" borderBottomWidth="1px">
                    <c-pseudo-box
                      as="a"
                      py="2"
                      px="3"
                      w="100%"
                      :_hover="{
                        textDecoration: 'none',
                        cursor: 'pointer',
                      }"
                      @click="goToProfile"
                      >Profile</c-pseudo-box
                    >
                  </c-list-item>
                  <c-list-item display="flex">
                    <c-pseudo-box
                      as="a"
                      py="2"
                      px="3"
                      w="100%"
                      color="vc-orange.400"
                      :_hover="{
                        textDecoration: 'none',
                        cursor: 'pointer',
                      }"
                      @click="onLogoutClick"
                      >Logout (v2.0.9)</c-pseudo-box
                    >
                  </c-list-item>
                </c-list>
              </c-popover-body>
            </c-popover-content>
          </c-popover>
          <c-link @click="isTeamDrawerOpen = !isTeamDrawerOpen">
            <svg>
              <use href="@/assets/icons/team-fill.svg#team" />
            </svg>
          </c-link>
        </div>
      </div>
      <c-link
        class="app__sidebar__mobile-btn"
        :class="{ 'app__sidebar__mobile-btn--open': isMenuOpen }"
        @click="isMenuOpen = !isMenuOpen"
      >
        <svg viewBox="0 0 100 100">
          <path
            class="line line1"
            d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
          />
          <path class="line line2" d="M 20,50 H 80" />
          <path
            class="line line3"
            d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
          />
        </svg>
      </c-link>
    </div>
    <div class="app__content">
      <event-modal />
      <feeling-modal />
      <router-view></router-view>
    </div>
    <template v-if="!isShareView">
      <!-- <c-box
        v-if="!$route.meta.hideNotes"
        position="absolute"
        bottom="0"
        right="154px"
        py="4"
        px="6"
        zIndex="0"
      >
        <c-popover bg="transparent" placement="top">
          <c-popover-trigger>
            <c-flex
              w="max-content"
              align="center"
              justify="flex-start"
              ml="auto"
              mt="4"
            >
              <c-button
                @click="$refs.notesCard.openNotes()"
                variant-color="vc-orange"
                color="#fff"
                mb="3"
                mr="3"
                variant="solid"
                size="md"
                left-icon="sticky-note"
                >Notes</c-button
              >

              <c-button
                @click="$refs.marketGoalCard.setFormDrawer(true)"
                variant-color="vc-orange"
                color="#fff"
                mb="3"
                variant="solid"
                size="md"
                left-icon="bullseye"
                >Goals</c-button
              >
            </c-flex>
          </c-popover-trigger>
        </c-popover>
      </c-box> -->

      <c-box
        v-if="!$route.meta.hideNotes"
        position="absolute"
        bottom="0"
        right="5px"
        px="6"
        zIndex="0"
      >
        <c-flex
          w="max-content"
          align="end"
          justify="flex-start"
          ml="auto"
          mt="4"
        >
          <QuickChatFooter
            :user="chats"
            v-for="(chats, index) in quickChats"
            :key="index"
          />

          <div
            class="quick_message foot"
            @click="$refs.chatsdrawer.openChatsDrawer()"
          >
            <div>
              <img
                :src="user.profilePhoto ? user.profilePhoto : placeHolderImage"
                alt=""
              />
              <span
                class="online_dot"
                v-if="user.OnlineStatus == 'true'"
              ></span>
            </div>

            <p>Messaging</p>

            <i class="fas fa-chevron-up"></i>
          </div>
        </c-flex>
      </c-box>
      <ChatDrawer ref="chatsdrawer" />

      <Notes ref="notesCard" />

      <Traction
        v-show="false"
        ref="marketGoalCard"
        :disabled="true"
        :type="'market_goal'"
      />

      <TeamDrawer :isOpen="isTeamDrawerOpen" @close="closeTeamDrawer" />
      <ProfileSettingsDrawer
        :isOpen="isSettingsDrawerOpen"
        @close="closeSettingsDrawer"
      />
      <CompanyDrawer
        :isOpen="isCompanyDrawerOpen"
        @close="closeCompanyDrawer"
      />
      <c-modal
        :is-open="isInviteModalOpen"
        :on-close="closeInviteModal"
        :closeOnOverlayClick="false"
      >
        <c-modal-content
          ref="content"
          w="50em"
          h="40em"
          maxWidth="100%"
          borderRadius="8px"
          overflow="hidden"
        >
          <c-modal-header
            h="80px"
            w="100%"
            color="#fff"
            backgroundImage="linear-gradient(to right, #103eba 0%, #1a86d0 100%);"
            display="flex"
            alignItems="center"
            >Welcome, {{ user.firstname }} {{ user.lastname }}</c-modal-header
          >
          <c-modal-body py="4">
            <c-box mb="6">
              <c-text fontWeight="600" fontSize="md"
                >Invite your team mates</c-text
              >
              <br />
              <c-text fontSize="md" fontWeight="400">
                Success is a team effort! Invite the rest of your team in order
                to secure a common understanding of who you are and where you
                are going
              </c-text>
            </c-box>
            <c-stack :spacing="3">
              <c-flex
                align="center"
                v-for="(invitation, invitationIndex) in invitations"
                :key="invitationIndex"
              >
                <c-box
                  bg="vc-orange.400"
                  w="20px"
                  h="20px"
                  mr="2"
                  borderRadius="50%"
                  v-if="!invitation.isSent"
                ></c-box>
                <c-box
                  bg="vue"
                  w="20px"
                  h="20px"
                  mr="2"
                  v-else
                  borderRadius="50%"
                ></c-box>
                <c-input
                  size="lg"
                  w="40%"
                  type="email"
                  mr="2"
                  flex="1"
                  v-model="invitation.email"
                  placeholder="Email address"
                ></c-input>
                <c-box width="200px" mr="2">
                  <AppSelect
                    :bordered="true"
                    :size="'large'"
                    :reduce="(accessType) => accessType.value"
                    label="title"
                    :placeholder="'Select Access Type'"
                    :options="accessTypes"
                    :borderColor="'light'"
                    :fullWidth="true"
                    v-model="invitation.accessType"
                  />
                </c-box>
                <c-box width="200px" mr="2">
                  <AppSelect
                    :bordered="true"
                    :size="'large'"
                    :reduce="(position) => position.name"
                    label="name"
                    :placeholder="'Select Role'"
                    :options="positions"
                    :borderColor="'light'"
                    :fullWidth="true"
                    v-model="invitation.position"
                  />
                </c-box>
                <c-button
                  :disabled="!isInvitationValid(invitation)"
                  variant-color="vc-orange"
                  variant="link"
                  v-if="!invitation.isSent"
                  @click="sendInvitation(invitation)"
                >
                  Send
                  <c-spinner
                    v-if="invitation.isSendingInvite"
                    ml="2"
                    color="vc-orange"
                    thickness="2px"
                  />
                </c-button>
                <c-button
                  :disabled="true"
                  variant-color="vue"
                  variant="link"
                  v-else
                  >Send</c-button
                >
              </c-flex>
              <c-link
                w="max-content"
                display="flex"
                alignItems="center"
                color="vc-orange.400"
                @click="addInvitation"
              >
                <svg
                  fill="#ef5323"
                  v-chakra="{
                    width: '25px',
                    height: '25px',
                  }"
                >
                  <use href="@/assets/icons/add-circle-line.svg#add-circle" />
                </svg>
                <c-text fontSize="sm" fontWeight="600" ml="2"
                  >Add Invitation</c-text
                >
              </c-link>
            </c-stack>
          </c-modal-body>
          <c-modal-footer>
            <c-link
              @click="closeInviteModal"
              color="vc-orange.400"
              fontWeight="600"
              >Go to Onboarding</c-link
            >
          </c-modal-footer>
        </c-modal-content>
        <c-modal-overlay />
      </c-modal>

      <c-modal
        :is-open="showTrialMessage"
        :on-close="closeFreeTrialModal"
        :closeOnOverlayClick="false"
      >
        <c-modal-content
          ref="content"
          w="25em"
          h="20em"
          maxWidth="100%"
          borderRadius="8px"
          overflow="hidden"
        >
          <c-modal-header
            h="50px"
            w="100%"
            color="#fff"
            backgroundImage="linear-gradient(to right, #103eba 0%, #1a86d0 100%);"
            display="flex"
            alignItems="center"
            >Welcome, {{ user.firstname }} {{ user.lastname }}</c-modal-header
          >
          <c-modal-body py="4">
            <c-box mb="6">
              <c-text fontWeight="600" fontSize="md"
                >Invitation for 2months Free Trial</c-text
              >

              <c-text fontSize="sm" fontWeight="500" mt="2">
                We are thrilled to let you enjoy our special trial plan as a
                token of our appreciation for your unwavering loyalty. This
                trial plan will grant you access to our premium features,
                allowing you to experience the full potential of our product.
              </c-text>
            </c-box>
          </c-modal-body>
          <c-modal-footer mb="2">
            <c-button
              @click="closeFreeTrialModal"
              variant="solid"
              variant-color="vc-orange"
              fontWeight="600"
              mb="2"
              >Start Free Trial</c-button
            >
          </c-modal-footer>
        </c-modal-content>
        <c-modal-overlay />
      </c-modal>
    </template>
  </div>
</template>

<script>
import store from '@/store';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
// eslint-disable-next-line no-unused-vars
import OneSignalVue from 'onesignal-vue';

import Notes from './components/notes/Notes.vue';
import ChatDrawer from './Messaging/components/ChatDrawer.vue';
import QuickChatFooter from './Messaging/components/QuickChatFooter.vue';
import Traction from './GrowthFramework/components/market-potential/Traction.vue';
import config from '@/mixins/config.js';
import { getUser, updateUser } from '@/services/user.js';
import AppSelect from './components/AppSelect.vue';
import { onLogout } from '@/vue-apollo.js';
import cloneDeep from 'lodash.clonedeep';
import {
  getCompanyMembers,
  subscribeToTeamMembers,
} from '@/services/foundation';
import { subscribeUnreadMessages } from '@/services/messages.js';

import ProfileSettingsDrawer from './components/ProfileSettingsDrawer.vue';
import CompanyDrawer from './components/CompanyDrawer.vue';
import TeamDrawer from './components/TeamDrawer.vue';
import {
  getShareCompany,
  subscribeToCompany,
  updateTeamMember,
  inviteCompanyMember,
} from '@/services/company';
import { subscribeCompanyCompletionLevel } from '@/services/common';

import { getTimelineData } from '@/services/timeline';
import { getSubscriptionPlans } from '../../services/subscription';
import sjcl from 'sjcl';

import EventModal from '@/views/App/Events/components/EventModal.vue';
// import TaggedSpeakersModal from "@/views/App/Explore/components/TaggedSpeakersModal.vue";
import placeHolderImage from '@/assets/img/profile_image.jpeg';
import FeelingModal from '@/views/App/Explore/components/FeelingModal.vue';

export default {
  mixins: [config],
  components: {
    FeelingModal,
    Notes,
    AppSelect,
    ProfileSettingsDrawer,
    CompanyDrawer,
    TeamDrawer,
    Traction,
    ChatDrawer,
    QuickChatFooter,
    EventModal,
    // TaggedSpeakersModal
  },
  data() {
    return {
      currentSwitchApp: 'business',
      placeHolderImage,
      show: false,
      isMenuOpen: false,
      isTeamDrawerOpen: false,
      isSettingsDrawerOpen: false,
      isCompanyDrawerOpen: false,
      isInviteModalOpen: false,
      // isFreeTrialModalOpen: true,
      accessTypes: [
        { title: 'Edit Access', value: 'edit' },
        { title: 'View Access', value: 'view' },
        { title: 'Custom Access', value: 'custom' },
      ],
      invitations: [
        {
          email: null,
          isSent: false,
          accessType: null,
          position: null,
          isSendingInvite: false,
        },
      ],
    };
  },
  async beforeRouteEnter(to, from, next) {
    const isInvite = to.query.is_invite;
    const shareId = to.query.company_id;
    if (!shareId) {
      const id = store.state.auth.user.id;
      const res = await getUser(id);
      const subscriptionRes = await getSubscriptionPlans();
      const user = res.data.user_by_pk;
      const userCreatedCompanies = user.companies;
      const userTeamCompanies = user.teams
        .filter((team) => team.company.createdBy !== user.id)
        .map((team) => team.company);
      const companies = [...userCreatedCompanies, ...userTeamCompanies];
      if (!companies.length) {
        if (user.lastAccessedCompanyId) {
          next({ name: 'NoTeam' });
        } else {
          next('/onboarding');
        }
        return;
      }
      next((vm) => {
        const hasAccessToLastCompany = companies.some(
          (company) => company.id === user.lastAccessedCompanyId
        );
        if (isInvite) {
          user.teams[0].isAccepted = true;
          vm.setInviteAsAccepted(user.teams[0].id);
          vm.setActiveCompany(
            cloneDeep(userTeamCompanies[userTeamCompanies.length - 1])
          );
        } else if (user.lastAccessedCompanyId && hasAccessToLastCompany) {
          const activeCompany = companies.find(
            (company) => company.id === user.lastAccessedCompanyId
          );
          vm.setActiveCompany(cloneDeep(activeCompany));
        } else {
          vm.setActiveCompany(cloneDeep(companies[0]));
          vm.setLastAccessedCompany({ company: companies[0], user });
        }
        vm.setUser(user);
        vm.setCompanies(companies);
        vm.getTeamMembers();
        vm.setShareView(false);
        vm.subscribeToCompletion();
        vm.setSubscriptionPlans(subscriptionRes.data.plan);
      });
    } else {
      const encryptedId = atob(shareId);

      try {
        // Decrypt the ID and password
        const decryptedId = sjcl.decrypt('E#2rjK7@Z9qy$L!', encryptedId);
        const convertId = Number(decryptedId);
        const res = await getShareCompany(convertId);

        next((vm) => {
          vm.setShareView(true);
          vm.setActiveCompany(res.data.company_by_pk);
        });
      } catch (err) {
        console.log(err);
      }
    }
  },
  computed: {
    ...mapState('tour', {
      isTourActive: (state) => state.isTourActive,
    }),
    ...mapGetters('subscription', ['getCurrentPlan']),
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
      companies: (state) => state.companies,
      isShareView: (state) => state.isShareView,
    }),
    ...mapState('subscription', {
      showTrialMessage: (state) => state.showTrialMessage,
    }),
    ...mapState('messaging', {
      quickChats: (state) => state.quickChats,
      unreadMessageCount: (state) => state.unreadMessageCount,
    }),
    ...mapGetters('company', ['isCompanyOwner', 'isCompanyAdmin']),
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    ...mapGetters('config', ['positions']),
    isStaging() {
      return (
        window.location.hostname.startsWith('develop') ||
        window.location.hostname.startsWith('localhost')
      );
    },
    isPaidPlan() {
      return (
        this.getCurrentPlan?.name == 'Premium' ||
        this.getCurrentPlan?.name == 'Pro'
      );
    },
    isTrialPlan() {
      return this.getCurrentPlan?.name == 'Trial';
    },
    isFreePlan() {
      return this.getCurrentPlan?.name == 'Free';
    },
  },
  watch: {
    $route() {
      if (this.isMenuOpen) {
        this.isMenuOpen = false;
      }
    },
  },
  created() {
    this.getGlobalConfig();
    if (this.$route.query.new_company) {
      this.openInviteModal();
    }
  },
  mounted() {
    this.stopTour();
    this.subscribeToCompanyData();
    this.setupOneSignal();
    this.getMarketPotentialData();
    this.subscribeToUnreadMessages();
    // this.trackBrowserTab();
  },
  methods: {
    goToProfile() {
      if (this.isStaging) {
        this.$router.push({
          name: 'Profile',
          params: {
            userId: this.user.id,
          },
        });
      }
    },
    openSideMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    ...mapActions({
      setUser: 'auth/setUser',
      logout: 'auth/logout',
      setActiveCompany: 'company/setActiveCompany',
      setCompanies: 'company/setCompanies',
      resetCompanyState: 'company/resetCompanyState',
      resetTimeline: 'timeline/resetTimeline',
      stopTour: 'tour/stopTour',
      setSubscriptionPlans: 'subscription/setPlans',
      setShowTrialMessage: 'subscription/setShowTrialMessage',
      updateMessageCount: 'messaging/updateMessageCount',
    }),
    ...mapMutations({
      setMembers: 'company/setMembers',
      setShareView: 'company/setShareView',
      setCategories: 'timeline/setCategories',
      setScenarios: 'timeline/setScenarios',
      setPhases: 'timeline/setPhases',
      setMilestones: 'timeline/setMilestones',
      setCompletionData: 'company/setCompletionData',
    }),
    async setupOneSignal() {
      if (!this.isShareView) {
        if (!(await this.$OneSignal.isPushNotificationsEnabled())) {
          this.$OneSignal.showSlidedownPrompt(true);
        }
        this.$OneSignal.setExternalUserId(`${this.user.id}`);
      }
    },
    subscribeToCompanyData() {
      const companyId = this.activeCompany.id;
      subscribeToCompany(companyId).subscribe({
        next: (res) => {
          this.setActiveCompany(cloneDeep(res.data.company_by_pk));
        },
        error(error) {
          console.error(error);
        },
      });
    },
    subscribeToUnreadMessages() {
      const userId = this.user.id;

      subscribeUnreadMessages(userId).subscribe({
        next: (res) => {
          const unReadMessages =
            res.data.channel_unread_message_aggregate.aggregate.sum.noOfUnread;

          if (unReadMessages > this.unreadMessageCount) {
            document.title = '(' + unReadMessages + ')' + ` New Messages`;
          }

          if (unReadMessages === 0) {
            document.title = 'Vibrantcreator App'; // Default title
          }

          this.updateMessageCount(unReadMessages);
        },
        error(error) {
          console.error(error);
        },
      });
    },
    getTeamMembers() {
      getCompanyMembers({
        companyId: this.activeCompany.id,
        isAdvisory: false,
      })
        .then((res) => {
          this.setMembers(res.data.team);
          this.subscribeToTeamMembers();
        })
        .catch((e) => {
          console.log({ e });
        });
    },
    subscribeToTeamMembers() {
      subscribeToTeamMembers({
        companyId: this.activeCompany.id,
        isAdvisory: false,
      }).subscribe({
        next: (data) => {
          this.setMembers(data.data.team);
        },
        error(error) {
          console.error(error);
        },
      });
    },
    setInviteAsAccepted(id) {
      return updateTeamMember({ id, set: { isAccepted: true } });
    },
    closeTeamDrawer() {
      this.isTeamDrawerOpen = false;
    },
    closeSettingsDrawer() {
      this.isSettingsDrawerOpen = false;
    },
    closeCompanyDrawer() {
      this.isCompanyDrawerOpen = false;
    },
    openInviteModal() {
      this.isInviteModalOpen = true;
    },
    closeFreeTrialModal() {
      this.setShowTrialMessage(false);
    },
    closeInviteModal() {
      this.isInviteModalOpen = false;
      this.$router.push({ path: '/app/growth-framework' });
    },
    setLastAccessedCompany({ company, user }) {
      return updateUser({
        id: user.id,
        set: { lastAccessedCompanyId: company.id },
      });
    },
    subscribeToCompletion() {
      const companyId = this.activeCompany.id;
      subscribeCompanyCompletionLevel(companyId).subscribe({
        next: (data) => {
          this.setCompletionData(data.data.company_by_pk);
        },
        error(error) {
          console.error(error);
        },
      });
    },
    getTimelineData() {
      getTimelineData(this.activeCompany.id).then((res) => {
        this.setCategories(res.data.calender_category);
        this.setPhases(res.data.phase);
        this.setScenarios(res.data.scenario);
        this.setMilestones(res.data.milestone);
      });
    },
    addInvitation() {
      this.invitations.push({
        email: null,
        isSent: false,
        accessType: null,
        position: null,
        isSendingInvite: false,
      });
    },
    isInvitationValid(invitation) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return (
        Object.keys(invitation)
          .filter((key) => !['isSent', 'isSendingInvite'].includes(key))
          .every((key) => invitation[key]) && re.test(invitation.email)
      );
    },
    sendInvitation(invitation) {
      invitation.isSendingInvite = true;
      const selectedPosition = this.positions.find(
        (position) => position.name === invitation.position
      );
      const { email, accessType, position } = invitation;
      const data = {
        email,
        accessType,
        position,
        department: selectedPosition
          ? selectedPosition.team_department.name
          : null,
        companyId: this.activeCompany.id,
        companyName: this.activeCompany.name,
      };
      inviteCompanyMember(data)
        .then(() => {
          invitation.isSendingInvite = false;
          invitation.isSent = true;
        })
        .catch((e) => {
          invitation.isSendingInvite = false;
          if (e.response.status === 400) {
            this.$toast({
              title: 'Error!!!',
              description: e.response.data.message,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
          }
        });
    },
    async onLogoutClick() {
      this.logout();
      await onLogout(this.$apollo.provider.defaultClient);
      this.$router.push('/auth/login');
      this.resetCompanyState(null);
      this.resetTimeline();
    },
    switchApp(app) {
      this.currentSwitchApp = app;
      // this.$toast({
      //   title: 'Switched Menu',
      //   description: 'You switched to ' + app + ' menu',
      //   status: 'success',
      //   duration: 5000,
      // });

      if (app === 'community') {
        this.$router.push({ path: '/app/explore' });
      }

      if (app === 'business') {
        this.$router.push({ path: '/app/growth-framework' });
      }
    },
    trackBrowserTab() {
      window.addEventListener('visibilitychange', function () {
        if (document.visibilityState === 'hidden') {
          console.log('Tab is being navigated away. tab click');

          updateUser({
            id: store.state.auth.user.id,
            set: { OnlineStatus: 'false' },
          });
        }
      });

      window.onbeforeunload = function () {
        console.log('Tab is being navigated away.');
        updateUser({
          id: store.state.auth.user.id,
          set: { OnlineStatus: 'false' },
        });

        return 'Do you really want to leave?';
      };
    },
  },
};
</script>

<style lang="scss">
.menu {
  position: absolute;
  right: 30px;
  top: 20px;
  z-index: 120;

  @media only screen and (min-width: 768px) {
    display: none;
  }
}
.app {
  @apply h-full w-full flex flex-col relative;
  &__sidebar {
    @apply flex flex-col justify-start relative px-4;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background-color: $color-primary;
    transition: height 300ms ease, opacity 200ms ease;

    &--open {
      @apply flex-col items-start px-0;
      .app__sidebar {
        &__header {
          @apply w-full flex items-start px-4;
          box-shadow: 0px 3px 6px #00000029;
          background-color: #063c62;
        }
        &__links {
          @apply block mt-6;
          li {
            a {
              @apply text-base;
            }
          }
        }
        &__footer {
          @apply flex w-full mb-9;
          &__links {
            @apply mx-auto;
            width: 60px;
          }
        }
      }
    }
    &--ontour {
      pointer-events: none;
      opacity: 0.5;
    }
    &__header {
      width: max-content;
      transition: width 300ms ease, background 300ms ease, box-shadow 300ms ease;
      &__trigger {
        @apply flex items-center justify-center py-4 cursor-pointer;
        svg {
          margin-left: 7px;
          width: 10px;
          height: 10px;
          fill: $color-orange;
        }
      }
      section.app__sidebar__header__company-dropdown {
        &:focus {
          box-shadow: 0px 3px 6px rgba(#bababa, 0.16);
        }
        a {
          @apply flex px-3 py-2;
          min-height: 40px;

          &:hover {
            text-decoration: none;
            background-color: rgba($color-primary, 0.5);
          }
        }
      }
    }
    &__links {
      @apply mb-5 w-full list-none hidden;
    }
    &__link {
      @apply w-full;
      height: 70px;
      position: relative;

      a {
        @apply w-full h-full flex flex-col items-center justify-center text-center py-2 px-2 font-semibold text-xs;
        svg {
          @apply mb-2;
          width: 24px;
          height: 24px;
          fill: #fff;
        }
        span {
          color: #fff;
        }
        &.active,
        &:hover {
          background-color: #fff;
          svg {
            fill: $color-primary;
          }
          span {
            color: $color-primary;
          }
        }
      }

      .disabled {
        cursor: not-allowed !important;
        // background: green;
        opacity: 0.2;
      }
    }
    &__footer {
      @apply mt-auto flex-col items-center justify-center hidden;
      &__links {
        @apply w-full flex items-center justify-center mt-3;
        height: 30px;
        a {
          svg {
            width: 16px;
            height: 16px;
            fill: #fff;
          }
        }
      }
    }
    &__mobile-btn {
      @apply absolute;
      right: 10px;
      top: 15px;
      svg {
        width: 3em;
        height: 3em;
      }
    }
  }
  &__content {
    @apply h-full max-h-full flex-1 overflow-y-scroll;
    background-color: #f9fbfd;
  }
  @media (max-width: 767px) {
    @apply flex-row;
    &__sidebar {
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 110;
      @apply h-full absolute flex-col items-center pt-2 pb-8 px-0;
      width: 5rem;

      &__header {
        @apply mb-5;
        height: 70px;
      }

      &__links {
        @apply block;
      }

      &__footer {
        @apply flex;
      }

      &__mobile-btn {
      }
    }
  }
  @screen lg {
    @apply flex-row;
    &__sidebar {
      @apply h-full flex-col items-center pt-2 pb-8 px-0;
      width: 6.8rem;
      &__header {
        @apply mb-5;
        height: 70px;
      }
      &__links {
        @apply block;
      }
      &__footer {
        @apply flex;
      }
      &__mobile-btn {
        @apply hidden;
      }
    }
    &__content {
      @apply px-6;
    }
  }
  @screen xl {
    &__content {
      @apply px-14;
    }
  }
}

/** Hamburger SVG && SVG transition */
.line {
  fill: none;
  stroke: #fff;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.app__sidebar__mobile-btn--open .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.app__sidebar__mobile-btn--open .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.app__sidebar__mobile-btn--open .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.pop_content {
  display: block;
  position: fixed !important;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate3d(44px, 604px, 0px);
  z-index: 999;
}

.foot {
  margin-left: 50px;
}
.quick_message {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #e2e8f4;
  border-radius: 8px 8px 0px 0px;
  color: #002b49;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 10px 20px;

  p {
    font-weight: bold;
    margin: 0 20px;
  }

  svg {
    margin-left: 20px;
  }

  div {
    display: flex;

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }

    .online_dot {
      height: 10px;
      width: 10px;
      background-color: #088b15;
      border-radius: 50%;
      display: inline-block;
      margin: 20px 0 0 -10px;
    }
  }
}

.custom_badge {
  position: absolute;
  top: 6px;
  right: 23px;
  background: #f00;
  color: #fff !important;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6rem;
}
// .chats_footer {
//   display: flex;
//   margin-right: 100px;
//   // fstyle first child div
//   div:last-child {
//     margin-right: 400px !important;
//   }
// }

.app_switcher {
  background: #fbcdbe;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 6px;
  margin: 0px 7px;

  svg {
    color: #ef5323;
    font-size: 22px;
    padding: 3px 5px;
  }

  .activeApp {
    background: #ef5323;
    border-radius: 5px;

    svg {
      color: #ffff;
    }
  }
}

.sidebar_notes {
  svg {
    color: #ef5323;
    font-size: 22px;
    cursor: pointer;
    background: white;
    padding: 6px;
    border-radius: 5px;
  }
}
</style>
