import { gql } from '@apollo/client/core';

export const globalBusinessConfigQuery = gql`
  query GlobalBusinessData {
    business_sector {
      name
      id
    }
    business_objective {
      value
      description
    }
    business_stage {
      name
      id
    }
    team_department {
      name
      id
      team_positions {
        id
        name
        departmentId
        team_department {
          name
        }
      }
    }
    sustainable_development_goals {
      name
      position
      image
      id
    }
    skill {
      name
      id
      isUserGenerated
      updatedAt
      createdAt
    }
  }
`;

export const tractionsQuery = gql`
  query tractionsQuery($companyId: Int!) {
    traction(where: { companyId: { _eq: $companyId } }) {
      companyId
      createdAt
      description
      id
      metricType
      title
      tractionType
      tractionValue
      updatedAt
    }
  }
`;

export const getCompanyCompletionLevelQuery = gql`
  query getCompanyCompletionLevel($companyId: Int!) {
    business_model(where: { companyId: { _eq: $companyId } }) {
      type
      progress
    }
    company_sdg(where: { companyId: { _eq: $companyId } }) {
      progress
    }
    company_skill(where: { companyId: { _eq: $companyId } }) {
      progress
    }
    competition(where: { companyId: { _eq: $companyId } }) {
      type
      progress
    }
    foundation(where: { companyId: { _eq: $companyId } }) {
      progress
      type
    }
    market_potential(where: { companyId: { _eq: $companyId } }) {
      progress
      type
    }
    risk_assessment(where: { companyId: { _eq: $companyId } }) {
      progress
      type
    }
  }
`;

export const subscribeToCompanyCompletionLevelQuery = gql`
  subscription subscribeToCompanyCompletionLevelQuery($companyId: Int!) {
    company_by_pk(id: $companyId) {
      id
      business_models {
        id
        type
        progress
      }
      company_sdgs {
        id
        progress
      }
      company_skills {
        id
        progress
      }
      competitions {
        id
        type
        progress
      }
      foundations {
        id
        progress
        type
      }
      market_potentials {
        id
        progress
        type
      }
      risk_assessments {
        id
        progress
        type
      }
    }
  }
`;

export const getCompanyQuery = gql`
  query getCompanyQuery($id: Int!) {
    company_by_pk(id: $id) {
      city
      country
      coverImage
      createdAt
      createdBy
      elevatorPitch
      id
      investmentEtaMetric
      investmentEtaValue
      logo
      name
      objective
      size
      stageId
      customerSegments
      business_stage {
        name
        id
      }
      business_objective {
        value
        description
      }
      company_sectors {
        id
        sectorId
        business_sector {
          name
          id
        }
      }
      billing_subscriptions {
        priceId
        status
        planId
        currentStartDate
      }
      aiCreditCount
      company_followers {
        follower {
          bio
          email
          coverImage
          id
          firstname
          lastname
        }
      }
    }
  }
`;
export const getCompanyFollowersQuery = gql`
  query getCompanyFollowers($companyId: Int!) {
    company_follower(where: {companyId: {_eq: $companyId}}) {
      follower {
        bio
        email
        id
        firstname
        lastname
        profilePhoto
        companies{
          id
          name
        }
      }
    }
  }
`
export const getShareCompanyQuery = gql`
  query getCompanyQuery($id: Int!) {
    company_by_pk(id: $id) {
      city
      country
      coverImage
      createdAt
      createdBy
      elevatorPitch
      id
      investmentEtaMetric
      investmentEtaValue
      logo
      name
      objective
      size
      stageId
      customerSegments
      business_stage {
        name
        id
      }
      business_objective {
        value
        description
      }
      company_sectors {
        id
        sectorId
        business_sector {
          name
          id
        }
      }
      aiCreditCount
    }
  }
`;

export const subscribeToCompanyQuery = gql`
  subscription subscribeToCompanyQuery($id: Int!) {
    company_by_pk(id: $id) {
      city
      country
      coverImage
      createdAt
      createdBy
      elevatorPitch
      id
      investmentEtaMetric
      investmentEtaValue
      logo
      name
      objective
      size
      stageId
      business_stage {
        name
        id
      }
      business_objective {
        value
        description
      }
      company_sectors {
        id
        sectorId
        business_sector {
          name
          id
        }
      }
      billing_subscriptions {
        priceId
        status
        planId
        currentStartDate
      }
      aiCreditCount
    }
  }
`;

export const getComponentActivityQuery = gql`
  query getComponentActivityQuery($where: activity_logs_bool_exp) {
    activity_logs(where: $where, order_by: { createdAt: desc }) {
      type
      userId
      id
      component
      data
      createdAt
      user {
        createdAt
        email
        firstname
        id
        lastname
        profilePhoto
        updatedAt
      }
    }
  }
`;

export const getMatchingCompaniesQuery = gql`
  query getMatchingCompaniesQuery($text: String!) {
    company(where: { name: { _ilike: $text } }) {
      city
      country
      coverImage
      createdAt
      createdBy
      elevatorPitch
      id
      investmentEtaMetric
      investmentEtaValue
      logo
      name
      objective
      size
    }
  }
`;

export const getCompaniesQuery = gql`
  query getCompaniesQuery($limit: Int!) {
    company(limit: $limit) {
      city
      country
      coverImage
      createdAt
      createdBy
      elevatorPitch
      id
      investmentEtaMetric
      investmentEtaValue
      logo
      name
      objective
      size
    }
  }
`;

export const getPopularCompanyToFollowQuery = gql`
  query getPopularCompanyToFollow($loggedInUser: Int!, $limit: Int!) @cached {
    company(
      where: {
        company_followers_aggregate: {
          count: {
            predicate: { _eq: 0 }
            filter: { userId: { _eq: $loggedInUser } }
          }
        }
      }
      limit: $limit
      order_by: { company_followers_aggregate: { count: desc_nulls_last } }
    ) {
      id
      name
      country
      logo
      company_followers {
        id
        follower{
          id
        }
      }
    }
  }
`;
